<template>
    <div>
        <slot v-if="loading">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>
        <div class="market-profile-mobile-pages" v-if="!loading && currencyExchangeProfile">
            <div class="market-symbol-row">
                <div class="market-symbol-row-right back-profile-page">
                    <router-link class="mobile-page-header-icon pointer back-icon" :to="{ name: 'GlobalExchanges'}">
                        <i class="header-uil-icon uil uil-angle-right-b"></i>
                    </router-link>
                    <div class="market-symbol-avatar" :style="avatarFinder"></div>
                    <div class="market-symbol-info">
                        <h1 class="market-symbol-title">{{currencyExchangeProfile.category_title}}</h1>
                        <span class="market-symbol-title-en">
                            تعداد ارز : 
                            <span class="market-symbol-market">{{currencyExchangeProfile.items.length}}</span>
                        </span>
                    </div>
                </div>
                <div class="market-symbol-row-left">
                    <div class="market-symbol-info">
                        <!-- <div class="market-symbol-price">
                            <span class="price" data-col="info.last_trade.PDrCotVal">3</span>
                        </div>
                        <span class="market-symbol-change">
                            <span class="change-tag" data-col="info.last_trade.last_change_percentage">
                                <span class="market_header_summary.yesterday_dt.plain">4</span>
                            </span>
                        </span> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="market-profile-inner"  v-if="!loading && currencyExchangeProfile">
            <ul>
                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">امکانات</div>
                        <div class="profile-inner-table-value"></div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">تلفن</div>
                        <div class="profile-inner-table-value"> {{ currencyExchangeProfile.supplier.phone ? currencyExchangeProfile.supplier.phone : '-' }}</div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">فکس</div>
                        <div class="profile-inner-table-value"> {{ currencyExchangeProfile.supplier.fax ? currencyExchangeProfile.supplier.fax : '-' }}</div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">ایمیل</div>
                        <div class="profile-inner-table-value"> {{ currencyExchangeProfile.supplier.email ? currencyExchangeProfile.supplier.email : '-' }}</div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">آدرس</div>
                        <div class="profile-inner-table-value profile-inner-table-value-address"> {{ currencyExchangeProfile.supplier.address ? currencyExchangeProfile.supplier.address : '-' }}</div>
                    </div>
                </li>
                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">ارزها</div>
                        <div class="profile-inner-table-value"></div>
                    </div>
                    <div class="header-actions-sw">
                        <div :class="'header-actions-sw-item ' + (type == 'sell' ? 'sw-item-active' : '')" @click="updateType('sell')">فروش</div>
                        <div :class="'header-actions-sw-item ' + (type == 'buy' ? 'sw-item-active' : '')" @click="updateType('buy')">خرید</div>
                    </div>
                </li>
            </ul>
        </div>
        <slot v-if="!loading">
            <div v-for="(item, index) in currencyExchangeProfile.items" :key="index" class="market-main-item" v-if="item.grade.includes('-'+type)">
                <!-- <router-link class="market-main-item-row r-low "  :to="{ name: 'MarketProfile', params: { name: item.id },}"> -->
                <div class="market-main-item-row r-low ">
                    <div class="market-main-item-col-logo">
                        <span :class="'new-flag flag-' + item.name.split('@').pop().replace('-sell', '').replace('-buy', '').replace('ex', '')"></span>
                    </div>
                    <div class="market-main-item-col item-col-right">
                        <div class="market-main-item-title">
                            <h2>{{ item.title }}</h2>
                        </div>
                        <div class="market-main-item-time"><i class="uil uil-clock"></i><span>{{ to_yyyy_mm_dd(item.updated_at) }}</span></div>
                    </div>
                    <div class="market-main-item-col item-col-left">
                        <div class="market-main-item-price"><span>{{ formatPrice(item.p) }}</span><div class="item-price-badge">ریال</div></div>
                        <div class="market-main-item-change"> </div>
                    </div>
                </div> 
            </div>
        </slot>
    </div>
</template>
<script>
// import NewsComments from '@/views/TgjuPostsMobile.vue'
import moment from "moment-jalaali";

export default {
    name: 'CurrencyExchangeProfileView',
    component: {
        // NewsComments,
    },
    data: function () {
        return {
            currencyExchangeProfile: {},
            cryptoName : {},
            type: 'sell',
            loading:false,
            supplier : {
                    "chat" : "چت",
                    "phone" : "تلفن",
                    "email" : "ایمیل",
                    "ticket" : "تیکت",
                    "android" : "اندرويد",
                    "exchange" : "صرافی",
                    "platform" : "بازار مبادلاتی",
                    "irt" : "تومان",
            },
        }
    },
    mounted() {
        // دریافت اطلاعات تحلیل های تایید شده ی عمومی
        this.loadData();
    },
    computed: {
        // آواتار یک شاخص را پیدا می کند
        avatarFinder: function () {
            if (this.currencyExchangeProfile.supplier.image) {
                return  {
                    'position' : 'relative',
                    'background-image': 'url(https://platform.tgju.org/files/images/' + this.currencyExchangeProfile.supplier.image + ')',
                }
            } 
            return  {
                'position' : 'relative',
                'background-image': 'url(https://static.tgju.org/images/no-icon.png)',
            }
        },
    },
    methods: {
        updateType(type) {
            this.type = type;
        },
        formatPrice(value) {
            if (value === null) {
                return '0';
            }

            value = parseFloat(value);
            return parseFloat(value.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        to_yyyy_mm_dd(date) {
            return moment(date).format("H:m:s - jYYYY/jMM/jDD");
        },
        findCryptoName(item) {
            var result = item.market.replace('-sell','').replace('-buy','');
            return this.cryptoName[result] ? this.cryptoName[result].fa : item.title;
        },
        finder(str) {
            if (str == null) {
                return '';
            }
            for (const [key, value] of Object.entries(this.supplier)) {
                str = str.replace(key, " " + value);
            }
            return str;
        },
        loadData() {
            this.loading = true;
            this.$helpers.makeRequest('POST', '/exchanges/currency-exchange-profile', {
                slug: this.$route.params.slug,
                id: this.$route.params.id,
            }).then(api_response => {

                if ( api_response.status == 200 ) {
                    this.currencyExchangeProfile = api_response.data.response.detail;
                    // this.cryptoName = api_response.data.response.detail.crypto_name;
                    this.loading = false;
                }
            });
        },
        // دکمه بازگشت
        back() {
            this.$router.back()
        },
    }
}
</script>